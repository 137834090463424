<template>
    <!--  Content Start -->
    <div class="sabc_contact_outer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-15">
                    <h1 class="text-center">Header bidder Test Page</h1>
                    <div class="row">
                        <div class="col-lg-4 text-center">
                            
                        </div>
                        <div class="col-lg-4 text-center">
                            <h4 >South Africa</h4>
                            <div id='div-1'></div>
                            <br>
                            <button class="btn btn-primary" @click="refreshBid()">Refresh ad</button>
                        </div>            
                        <div class="col-lg-4 text-center">    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  Content end -->
</template>

<script>

    export default {
        name: 'ads_test_page',
        data() {
            return {
                slot1:[],
                googletag:[],
                pbjs:[],
            }
        },
        components: {},
        mounted() {
            let scripts = [
                "https://meta.resetdigital.co/Scripts/smart.js?px=1000250",
                "//www.googletagservices.com/tag/js/gpt.js",
                "//cdn.jsdelivr.net/npm/prebid.js@latest/dist/not-for-prod/prebid.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            // var _this = this;
            // setTimeout(function () {
            //     _this.loadScriptTest();
            // }, 3000);

            this.loadScriptTest();
            this.refreshBid();
        },
        methods: {
            loadScriptTest(){
                
                let sizes = [[300, 250]];

                this.googletag.cmd = [];
                this.pbjs.que = [];

                let adUnits = [{
                    code: '/19968336/header-bid-tag-1',
                    mediaTypes: {
                        banner: {
                            sizes: sizes
                        }
                    },
                    bids: [{
                        bidder: 'resetdigital',
                        params: {
                            pubId: '180',
                            endpoint: 'https://ads-sa.resetsrv.com',
                            placement: '12345',
                            zoneId: {
                                test: 1
                            }
                        }
                    }]
                }];
                this.pbjs.que.push(function() {
                    this.pbjs.addAdUnits(adUnits);
                });

                this.googletag.cmd.push(function() {
                    this.slot1 = this.googletag.defineSlot('/19968336/header-bid-tag-1', [[300, 250]], 'div-1')
                        .addService(this.googletag.pubads());
                    this.googletag.pubads().disableInitialLoad();
                    this.googletag.pubads().enableSingleRequest();
                    this.googletag.enableServices();
                });

                this.googletag.cmd.push(function() {
                    this.googletag.display('div-1');
                });

            },
            refreshBid() {
                this.pbjs.que.push(function() {
                    this.pbjs.requestBids({
                        timeout: 50,
                        adUnitCodes: ['/19968336/header-bid-tag-1'],
                        bidsBackHandler: function() {
                            this.pbjs.setTargetingForGPTAsync(['/19968336/header-bid-tag-1']);
                            this.googletag.pubads().refresh([this.slot1]);
                        }
                    });
                });
            }
        }
    }
</script>